export const provinces: { [key: string]: string[]} = {
  "กระบี่": [
    "เกาะลันตา",
    "เขาพนม",
    "คลองท่อม",
    "ปลายพระยา",
    "เมืองกระบี่",
    "ลำทับ",
    "เหนือคลอง",
    "อ่าวลึก"
  ],
  "กรุงเทพมหานคร": [
    "เขตคลองเตย",
    "เขตคลองสาน",
    "เขตคลองสามวา",
    "เขตคันนายาว",
    "เขตจตุจักร",
    "เขตจอมทอง",
    "เขตดอนเมือง",
    "เขตดินแดง",
    "เขตดุสิต",
    "เขตตลิ่งชัน",
    "เขตทวีวัฒนา",
    "เขตทุ่งครุ",
    "เขตธนบุรี",
    "เขตบางกอกน้อย",
    "เขตบางกอกใหญ่",
    "เขตบางกะปิ",
    "เขตบางขุนเทียน",
    "เขตบางเขน",
    "เขตบางคอแหลม",
    "เขตบางแค",
    "เขตบางซื่อ",
    "เขตบางนา",
    "เขตบางบอน",
    "เขตบางพลัด",
    "เขตบางรัก",
    "เขตบึงกุ่ม",
    "เขตปทุมวัน",
    "เขตประเวศ",
    "เขตป้อมปราบศัตรูพ่าย",
    "เขตพญาไท",
    "เขตพระโขนง",
    "เขตพระนคร",
    "เขตภาษีเจริญ",
    "เขตมีนบุรี",
    "เขตยานนาวา",
    "เขตราชเทวี",
    "เขตราษฎร์บูรณะ",
    "เขตลาดกระบัง",
    "เขตลาดพร้าว",
    "เขตวังทองหลาง",
    "เขตวัฒนา",
    "เขตสวนหลวง",
    "เขตสะพานสูง",
    "เขตสัมพันธวงศ์",
    "เขตสาทร",
    "เขตสายไหม",
    "เขตหนองแขม",
    "เขตหนองจอก",
    "เขตหลักสี่",
    "เขตห้วยขวาง"
  ],
  "กาญจนบุรี": [
    "ด่านมะขามเตี้ย",
    "ทองผาภูมิ",
    "ท่าม่วง",
    "ท่ามะกา",
    "ไทรโยค",
    "บ่อพลอย",
    "พนมทวน",
    "เมืองกาญจนบุรี",
    "เลาขวัญ",
    "ศรีสวัสดิ์",
    "สังขละบุรี",
    "หนองปรือ",
    "ห้วยกระเจา"
  ],
  "กาฬสินธุ์": [
    "กมลาไสย",
    "กุฉินารายณ์",
    "เขาวง",
    "คำม่วง",
    "ฆ้องชัย",
    "ดอนจาน",
    "ท่าคันโท",
    "นาคู",
    "นามน",
    "เมืองกาฬสินธุ์",
    "ยางตลาด",
    "ร่องคำ",
    "สมเด็จ",
    "สหัสขันธ์",
    "สามชัย",
    "หนองกุงศรี",
    "ห้วยผึ้ง",
    "ห้วยเม็ก"
  ],
  "กำแพงเพชร": [
    "โกสัมพีนคร",
    "ขาณุวรลักษบุรี",
    "คลองขลุง",
    "คลองลาน",
    "ทรายทองวัฒนา",
    "ไทรงาม",
    "บึงสามัคคี",
    "ปางศิลาทอง",
    "พรานกระต่าย",
    "เมืองกำแพงเพชร",
    "ลานกระบือ"
  ],
  "ขอนแก่น": [
    "กระนวน",
    "เขาสวนกวาง",
    "โคกโพธิ์ไชย",
    "ชนบท",
    "ชุมแพ",
    "ซำสูง",
    "น้ำพอง",
    "โนนศิลา",
    "บ้านไผ่",
    "บ้านฝาง",
    "บ้านแฮด",
    "เปือยน้อย",
    "พระยืน",
    "พล",
    "ภูผาม่าน",
    "ภูเวียง",
    "มัญจาคีรี",
    "เมืองขอนแก่น",
    "เวียงเก่า",
    "แวงน้อย",
    "แวงใหญ่",
    "สีชมพู",
    "หนองนาคำ",
    "หนองเรือ",
    "หนองสองห้อง",
    "อุบลรัตน์"
  ],
  "จันทบุรี": [
    "แก่งหางแมว",
    "ขลุง",
    "เขาคิชฌกูฏ",
    "ท่าใหม่",
    "นายายอาม",
    "โป่งน้ำร้อน",
    "มะขาม",
    "เมืองจันทบุรี",
    "สอยดาว",
    "แหลมสิงห์"
  ],
  "ฉะเชิงเทรา": [
    "คลองเขื่อน",
    "ท่าตะเกียบ",
    "บางคล้า",
    "บางน้ำเปรี้ยว",
    "บางปะกง",
    "บ้านโพธิ์",
    "แปลงยาว",
    "พนมสารคาม",
    "เมืองฉะเชิงเทรา",
    "ราชสาส์น",
    "สนามชัยเขต"
  ],
  "ชลบุรี": [
    "เกาะจันทร์",
    "เกาะสีชัง",
    "บ่อทอง",
    "บางละมุง",
    "บ้านบึง",
    "พนัสนิคม",
    "พานทอง",
    "เมืองชลบุรี",
    "ศรีราชา",
    "สัตหีบ",
    "หนองใหญ่"
  ],
  "ชัยนาท": [
    "เนินขาม",
    "มโนรมย์",
    "เมืองชัยนาท",
    "วัดสิงห์",
    "สรรคบุรี",
    "สรรพยา",
    "หนองมะโมง",
    "หันคา"
  ],
  "ชัยภูมิ": [
    "เกษตรสมบูรณ์",
    "แก้งคร้อ",
    "คอนสวรรค์",
    "คอนสาร",
    "จัตุรัส",
    "ซับใหญ่",
    "เทพสถิต",
    "เนินสง่า",
    "บ้านเขว้า",
    "บ้านแท่น",
    "บำเหน็จณรงค์",
    "ภักดีชุมพล",
    "ภูเขียว",
    "เมืองชัยภูมิ",
    "หนองบัวแดง",
    "หนองบัวระเหว"
  ],
  "ชุมพร": [
    "ท่าแซะ",
    "ทุ่งตะโก",
    "ปะทิว",
    "พะโต๊ะ",
    "เมืองชุมพร",
    "ละแม",
    "สวี",
    "หลังสวน"
  ],
  "เชียงราย": [
    "ขุนตาล",
    "เชียงของ",
    "เชียงแสน",
    "ดอยหลวง",
    "เทิง",
    "ป่าแดด",
    "พญาเม็งราย",
    "พาน",
    "เมืองเชียงราย",
    "แม่จัน",
    "แม่ฟ้าหลวง",
    "แม่ลาว",
    "แม่สรวย",
    "แม่สาย",
    "เวียงแก่น",
    "เวียงชัย",
    "เวียงเชียงรุ้ง",
    "เวียงป่าเป้า"
  ],
  "เชียงใหม่": [
    "กัลยาณิวัฒนา",
    "จอมทอง",
    "เชียงดาว",
    "ไชยปราการ",
    "ดอยเต่า",
    "ดอยสะเก็ด",
    "ดอยหล่อ",
    "ฝาง",
    "พร้าว",
    "เมืองเชียงใหม่",
    "แม่แจ่ม",
    "แม่แตง",
    "แม่ริม",
    "แม่วาง",
    "แม่ออน",
    "แม่อาย",
    "เวียงแหง",
    "สะเมิง",
    "สันกำแพง",
    "สันทราย",
    "สันป่าตอง",
    "สารภี",
    "หางดง",
    "อมก๋อย",
    "ฮอด"
  ],
  "ตรัง": [
    "กันตัง",
    "นาโยง",
    "ปะเหลียน",
    "เมืองตรัง",
    "ย่านตาขาว",
    "รัษฎา",
    "วังวิเศษ",
    "สิเกา",
    "ห้วยยอด",
    "หาดสำราญ"
  ],
  "ตราด": [
    "เกาะกูด",
    "เกาะช้าง",
    "เขาสมิง",
    "คลองใหญ่",
    "บ่อไร่",
    "เมืองตราด",
    "แหลมงอบ"
  ],
  "ตาก": [
    "ท่าสองยาง",
    "บ้านตาก",
    "พบพระ",
    "เมืองตาก",
    "แม่ระมาด",
    "แม่สอด",
    "วังเจ้า",
    "สามเงา",
    "อุ้มผาง"
  ],
  "นครนายก": ["บ้านนา", "ปากพลี", "เมืองนครนายก", "องครักษ์"],
  "นครปฐม": [
    "กำแพงแสน",
    "ดอนตูม",
    "นครชัยศรี",
    "บางเลน",
    "พุทธมณฑล",
    "เมืองนครปฐม",
    "สามพราน"
  ],
  "นครพนม": [
    "ท่าอุเทน",
    "ธาตุพนม",
    "นาแก",
    "นาทม",
    "นาหว้า",
    "บ้านแพง",
    "ปลาปาก",
    "โพนสวรรค์",
    "เมืองนครพนม",
    "เรณูนคร",
    "วังยาง",
    "ศรีสงคราม"
  ],
  "นครราชสีมา": [
    "แก้งสนามนาง",
    "ขามทะเลสอ",
    "ขามสะแกแสง",
    "คง",
    "ครบุรี",
    "จักราช",
    "เฉลิมพระเกียรติ",
    "ชุมพวง",
    "โชคชัย",
    "ด่านขุนทด",
    "เทพารักษ์",
    "โนนแดง",
    "โนนไทย",
    "โนนสูง",
    "บัวลาย",
    "บัวใหญ่",
    "บ้านเหลื่อม",
    "ประทาย",
    "ปักธงชัย",
    "ปากช่อง",
    "พระทองคำ",
    "พิมาย",
    "เมืองนครราชสีมา",
    "เมืองยาง",
    "ลำทะเมนชัย",
    "วังน้ำเขียว",
    "สีคิ้ว",
    "สีดา",
    "สูงเนิน",
    "เสิงสาง",
    "หนองบุนนาก",
    "ห้วยแถลง"
  ],
  "นครศรีธรรมราช": [
    "ขนอม",
    "จุฬาภรณ์",
    "ฉวาง",
    "เฉลิมพระเกียรติ",
    "ชะอวด",
    "ช้างกลาง",
    "เชียรใหญ่",
    "ถ้ำพรรณรา",
    "ท่าศาลา",
    "ทุ่งสง",
    "ทุ่งใหญ่",
    "นบพิตำ",
    "นาบอน",
    "บางขัน",
    "ปากพนัง",
    "พรหมคีรี",
    "พระพรหม",
    "พิปูน",
    "เมืองนครศรีธรรมราช",
    "ร่อนพิบูลย์",
    "ลานสกา",
    "สิชล",
    "หัวไทร"
  ],
  "นครสวรรค์": [
    "เก้าเลี้ยว",
    "โกรกพระ",
    "ชุมตาบง",
    "ชุมแสง",
    "ตากฟ้า",
    "ตาคลี",
    "ท่าตะโก",
    "บรรพตพิสัย",
    "พยุหะคีรี",
    "ไพศาลี",
    "เมืองนครสวรรค์",
    "แม่เปิน",
    "แม่วงก์",
    "ลาดยาว",
    "หนองบัว"
  ],
  "นนทบุรี": [
    "ไทรน้อย",
    "บางกรวย",
    "บางบัวทอง",
    "บางใหญ่",
    "ปากเกร็ด",
    "เมืองนนทบุรี"
  ],
  "นราธิวาส": [
    "จะแนะ",
    "เจาะไอร้อง",
    "ตากใบ",
    "บาเจาะ",
    "เมืองนราธิวาส",
    "ยี่งอ",
    "ระแงะ",
    "รือเสาะ",
    "แว้ง",
    "ศรีสาคร",
    "สุคิริน",
    "สุไหงโก-ลก",
    "สุไหงปาดี"
  ],
  "น่าน": [
    "เฉลิมพระเกียรติ",
    "เชียงกลาง",
    "ท่าวังผา",
    "ทุ่งช้าง",
    "นาน้อย",
    "นาหมื่น",
    "บ่อเกลือ",
    "บ้านหลวง",
    "ปัว",
    "ภูเพียง",
    "เมืองน่าน",
    "แม่จริม",
    "เวียงสา",
    "สองแคว",
    "สันติสุข"
  ],
  "บึงกาฬ": [
    "เซกา",
    "โซ่พิสัย",
    "บึงกาฬ",
    "บึงโขงหลง",
    "บุ่งคล้า",
    "ปากคาด",
    "เมืองบึงกาฬ",
    "ศรีวิไล"
  ],
  "บุรีรัมย์": [
    "กระสัง",
    "คูเมือง",
    "แคนดง",
    "เฉลิมพระเกียรติ",
    "ชำนิ",
    "นางรอง",
    "นาโพธิ์",
    "โนนดินแดง",
    "โนนสุวรรณ",
    "บ้านกรวด",
    "บ้านด่าน",
    "บ้านใหม่ไชยพจน์",
    "ประโคนชัย",
    "ปะคำ",
    "พลับพลาชัย",
    "พุทไธสง",
    "เมืองบุรีรัมย์",
    "ละหานทราย",
    "ลำปลายมาศ",
    "สตึก",
    "หนองกี่",
    "หนองหงส์",
    "ห้วยราช"
  ],
  "ปทุมธานี": [
    "คลองหลวง",
    "ธัญบุรี",
    "เมืองปทุมธานี",
    "ลาดหลุมแก้ว",
    "ลำลูกกา",
    "สามโคก",
    "หนองเสือ"
  ],
  "ประจวบคีรีขันธ์": [
    "กุยบุรี",
    "ทับสะแก",
    "บางสะพาน",
    "บางสะพานน้อย",
    "ปราณบุรี",
    "เมืองประจวบคีรีขันธ์",
    "สามร้อยยอด",
    "หัวหิน"
  ],
  "ปราจีนบุรี": [
    "กบินทร์บุรี",
    "นาดี",
    "บ้านสร้าง",
    "ประจันตคาม",
    "เมืองปราจีนบุรี",
    "ศรีมหาโพธิ",
    "ศรีมโหสถ"
  ],
  "ปัตตานี": [
    "กะพ้อ",
    "โคกโพธิ์",
    "ทุ่งยางแดง",
    "ปะนาเระ",
    "มายอ",
    "เมืองปัตตานี",
    "แม่ลาน",
    "ไม้แก่น",
    "ยะรัง",
    "ยะหริ่ง",
    "สายบุรี",
    "หนองจิก"
  ],
  "พระนครศรีอยุธยา": [
    "ท่าเรือ",
    "นครหลวง",
    "บางซ้าย",
    "บางไทร",
    "บางบาล",
    "บางปะหัน",
    "บางปะอิน",
    "บ้านแพรก",
    "ผักไห่",
    "พระนครศรีอยุธยา",
    "ภาชี",
    "มหาราช",
    "ลาดบัวหลวง",
    "วังน้อย",
    "เสนา",
    "อุทัย"
  ],
  "พะเยา": [
    "จุน",
    "เชียงคำ",
    "เชียงม่วน",
    "ดอกคำใต้",
    "ปง",
    "ภูกามยาว",
    "ภูซาง",
    "เมืองพะเยา",
    "แม่ใจ"
  ],
  "พังงา": [
    "กะปง",
    "เกาะยาว",
    "คุระบุรี",
    "ตะกั่วทุ่ง",
    "ตะกั่วป่า",
    "ทับปุด",
    "ท้ายเหมือง",
    "เมืองพังงา"
  ],
  "พัทลุง": [
    "กงหรา",
    "เขาชัยสน",
    "ควนขนุน",
    "ตะโหมด",
    "บางแก้ว",
    "ปากพะยูน",
    "ป่าบอน",
    "ป่าพะยอม",
    "เมืองพัทลุง",
    "ศรีนครินทร์",
    "ศรีบรรพต"
  ],
  "พิจิตร": [
    "ดงเจริญ",
    "ตะพานหิน",
    "ทับคล้อ",
    "บางมูลนาก",
    "บึงนาราง",
    "โพทะเล",
    "โพธิ์ประทับช้าง",
    "เมืองพิจิตร",
    "วชิรบารมี",
    "วังทรายพูน",
    "สากเหล็ก",
    "สามง่าม"
  ],
  "พิษณุโลก": [
    "ชาติตระการ",
    "นครไทย",
    "เนินมะปราง",
    "บางกระทุ่ม",
    "บางระกำ",
    "พรหมพิราม",
    "เมืองพิษณุโลก",
    "วังทอง",
    "วัดโบสถ์"
  ],
  "เพชรบุรี": [
    "แก่งกระจาน",
    "เขาย้อย",
    "ชะอำ",
    "ท่ายาง",
    "บ้านลาด",
    "บ้านแหลม",
    "เมืองเพชรบุรี",
    "หนองหญ้าปล้อง"
  ],
  "เพชรบูรณ์": [
    "เขาค้อ",
    "ชนแดน",
    "น้ำหนาว",
    "บึงสามพัน",
    "เมืองเพชรบูรณ์",
    "วังโป่ง",
    "วิเชียรบุรี",
    "ศรีเทพ",
    "หนองไผ่",
    "หล่มเก่า",
    "หล่มสัก"
  ],
  "แพร่": [
    "เด่นชัย",
    "เมืองแพร่",
    "ร้องกวาง",
    "ลอง",
    "วังชิ้น",
    "สอง",
    "สูงเม่น",
    "หนองม่วงไข่"
  ],
  "ภูเก็ต": ["กะทู้", "ถลาง", "เมืองภูเก็ต"],
  "มหาสารคาม": [
    "กันทรวิชัย",
    "กุดรัง",
    "แกดำ",
    "โกสุมพิสัย",
    "ชื่นชม",
    "เชียงยืน",
    "นาเชือก",
    "นาดูน",
    "บรบือ",
    "พยัคฆภูมิพิสัย",
    "เมืองมหาสารคาม",
    "ยางสีสุราช",
    "วาปีปทุม"
  ],
  "มุกดาหาร": [
    "คำชะอี",
    "ดงหลวง",
    "ดอนตาล",
    "นิคมคำสร้อย",
    "เมืองมุกดาหาร",
    "หนองสูง",
    "หว้านใหญ่"
  ],
  "แม่ฮ่องสอน": [
    "ขุนยวม",
    "ปางมะผ้า",
    "ปาย",
    "เมืองแม่ฮ่องสอน",
    "แม่ลาน้อย",
    "แม่สะเรียง",
    "สบเมย"
  ],
  "ยโสธร": [
    "กุดชุม",
    "ค้อวัง",
    "คำเขื่อนแก้ว",
    "ทรายมูล",
    "ไทยเจริญ",
    "ป่าติ้ว",
    "มหาชนะชัย",
    "เมืองยโสธร",
    "เลิงนกทา"
  ],
  "ยะลา": [
    "กรงปินัง",
    "กาบัง",
    "ธารโต",
    "บันนังสตา",
    "เบตง",
    "เมืองยะลา",
    "ยะหา",
    "รามัน"
  ],
  "ร้อยเอ็ด": [
    "เกษตรวิสัย",
    "จตุรพักตรพิมาน",
    "จังหาร",
    "เชียงขวัญ",
    "ทุ่งเขาหลวง",
    "ธวัชบุรี",
    "ปทุมรัตต์",
    "พนมไพร",
    "โพธิ์ชัย",
    "โพนทราย",
    "โพนทอง",
    "เมยวดี",
    "เมืองร้อยเอ็ด",
    "เมืองสรวง",
    "ศรีสมเด็จ",
    "สุวรรณภูมิ",
    "เสลภูมิ",
    "หนองพอก",
    "หนองฮี",
    "อาจสามารถ"
  ],
  "ระนอง": ["กระบุรี", "กะเปอร์", "เมืองระนอง", "ละอุ่น", "สุขสำราญ"],
  "ระยอง": [
    "แกลง",
    "เขาชะเมา",
    "นิคมพัฒนา",
    "บ้านค่าย",
    "บ้านฉาง",
    "ปลวกแดง",
    "เมืองระยอง",
    "วังจันทร์"
  ],
  "ราชบุรี": [
    "จอมบึง",
    "ดำเนินสะดวก",
    "บางแพ",
    "บ้านคา",
    "บ้านโป่ง",
    "ปากท่อ",
    "โพธาราม",
    "เมืองราชบุรี",
    "วัดเพลง",
    "สวนผึ้ง"
  ],
  "ลพบุรี": [
    "โคกเจริญ",
    "โคกสำโรง",
    "ชัยบาดาล",
    "ท่าวุ้ง",
    "ท่าหลวง",
    "บ้านหมี่",
    "พัฒนานิคม",
    "เมืองลพบุรี",
    "ลำสนธิ",
    "สระโบสถ์",
    "หนองม่วง"
  ],
  "ลำปาง": [
    "เกาะคา",
    "งาว",
    "แจ้ห่ม",
    "เถิน",
    "เมืองปาน",
    "เมืองลำปาง",
    "แม่ทะ",
    "แม่พริก",
    "แม่เมาะ",
    "วังเหนือ",
    "สบปราบ",
    "เสริมงาม",
    "ห้างฉัตร"
  ],
  "ลำพูน": [
    "ทุ่งหัวช้าง",
    "บ้านธิ",
    "บ้านโฮ่ง",
    "ป่าซาง",
    "เมืองลำพูน",
    "แม่ทา",
    "ลี้",
    "เวียงหนองล่อง"
  ],
  "เลย": [
    "เชียงคาน",
    "ด่านซ้าย",
    "ท่าลี่",
    "นาด้วง",
    "นาแห้ว",
    "ปากชม",
    "ผาขาว",
    "ภูกระดึง",
    "ภูเรือ",
    "ภูหลวง",
    "เมืองเลย",
    "วังสะพุง",
    "หนองหิน",
    "เอราวัณ"
  ],
  "ศรีสะเกษ": [
    "กันทรลักษ์",
    "กันทรารมย์",
    "ขุขันธ์",
    "ขุนหาญ",
    "น้ำเกลี้ยง",
    "โนนคูณ",
    "บึงบูรพ์",
    "เบญจลักษ์",
    "ปรางค์กู่",
    "พยุห์",
    "โพธิ์ศรีสุวรรณ",
    "ไพรบึง",
    "ภูสิงห์",
    "เมืองจันทร์",
    "เมืองศรีสะเกษ",
    "ยางชุมน้อย",
    "ราษีไศล",
    "วังหิน",
    "ศรีรัตนะ",
    "ศิลาลาด",
    "ห้วยทับทัน",
    "อุทุมพรพิสัย"
  ],
  "สกลนคร": [
    "กุดบาก",
    "กุสุมาลย์",
    "คำตากล้า",
    "โคกศรีสุพรรณ",
    "เจริญศิลป์",
    "เต่างอย",
    "นิคมน้ำอูน",
    "บ้านม่วง",
    "พรรณานิคม",
    "พังโคน",
    "โพนนาแก้ว",
    "ภูพาน",
    "เมืองสกลนคร",
    "วานรนิวาส",
    "วาริชภูมิ",
    "สว่างแดนดิน",
    "ส่องดาว",
    "อากาศอำนวย"
  ],
  "สงขลา": [
    "กระแสสินธุ์",
    "คลองหอยโข่ง",
    "ควนเนียง",
    "จะนะ",
    "เทพา",
    "นาทวี",
    "นาหม่อม",
    "บางกล่ำ",
    "เมืองสงขลา",
    "ระโนด",
    "รัตภูมิ",
    "สทิงพระ",
    "สะเดา",
    "สะบ้าย้อย",
    "สิงหนคร",
    "หาดใหญ่"
  ],
  "สตูล": [
    "ควนกาหลง",
    "ควนโดน",
    "ท่าเเพ",
    "ทุ่งหว้า",
    "มะนัง",
    "เมืองสตูล",
    "ละงู"
  ],
  "สมุทรปราการ": [
    "บางบ่อ",
    "บางพลี",
    "บางเสาธง",
    "พระประแดง",
    "พระสมุทรเจดีย์",
    "เมืองสมุทรปราการ"
  ],
  "สมุทรสงคราม": ["บางคนที", "เมืองสมุทรสงคราม", "อัมพวา"],
  "สมุทรสาคร": ["กระทุ่มแบน", "บ้านแพ้ว", "เมืองสมุทรสาคร"],
  "สระแก้ว": [
    "เขาฉกรรจ์",
    "คลองหาด",
    "โคกสูง",
    "ตาพระยา",
    "เมืองสระแก้ว",
    "วังน้ำเย็น",
    "วังสมบูรณ์",
    "วัฒนานคร",
    "อรัญประเทศ"
  ],
  "สระบุรี": [
    "แก่งคอย",
    "เฉลิมพระเกียรติ",
    "ดอนพุด",
    "บ้านหมอ",
    "พระพุทธบาท",
    "มวกเหล็ก",
    "เมืองสระบุรี",
    "วังม่วง",
    "วิหารแดง",
    "เสาไห้",
    "หนองแค",
    "หนองแซง",
    "หนองโดน"
  ],
  "สิงห์บุรี": [
    "ค่ายบางระจัน",
    "ท่าช้าง",
    "บางระจัน",
    "พรหมบุรี",
    "เมืองสิงห์บุรี",
    "อินทร์บุรี"
  ],
  "สุโขทัย": [
    "กงไกรลาศ",
    "คีรีมาศ",
    "ทุ่งเสลี่ยม",
    "บ้านด่านลานหอย",
    "เมืองสุโขทัย",
    "ศรีนคร",
    "ศรีสัชนาลัย",
    "ศรีสำโรง",
    "สวรรคโลก"
  ],
  "สุพรรณบุรี": [
    "ดอนเจดีย์",
    "ด่านช้าง",
    "เดิมบางนางบวช",
    "บางปลาม้า",
    "เมืองสุพรรณบุรี",
    "ศรีประจันต์",
    "สองพี่น้อง",
    "สามชุก",
    "หนองหญ้าไซ",
    "อู่ทอง"
  ],
  "สุราษฎร์ธานี": [
    "กาญจนดิษฐ์",
    "เกาะพะงัน",
    "เกาะสมุย",
    "คีรีรัฐนิคม",
    "เคียนซา",
    "ชัยบุรี",
    "ไชยา",
    "ดอนสัก",
    "ท่าฉาง",
    "ท่าชนะ",
    "บ้านตาขุน",
    "บ้านนาเดิม",
    "บ้านนาสาร",
    "พนม",
    "พระแสง",
    "พุนพิน",
    "เมืองสุราษฎร์ธานี",
    "วิภาวดี",
    "เวียงสระ"
  ],
  "สุรินทร์": [
    "กาบเชิง",
    "เขวาสินรินทร์",
    "จอมพระ",
    "ชุมพลบุรี",
    "ท่าตูม",
    "โนนนารายณ์",
    "บัวเชด",
    "ปราสาท",
    "พนมดงรัก",
    "เมืองสุรินทร์",
    "รัตนบุรี",
    "ลำดวน",
    "ศรีณรงค์",
    "ศีขรภูมิ",
    "สนม",
    "สังขะ",
    "สำโรงทาบ"
  ],
  "หนองคาย": [
    "เซกา",
    "โซ่พิสัย",
    "ท่าบ่อ",
    "บึงกาฬ",
    "บึงโขงหลง",
    "บุ่งคล้า",
    "ปากคาด",
    "เฝ้าไร่",
    "พรเจริญ",
    "โพธิ์ตาก",
    "โพนพิสัย",
    "เมืองหนองคาย",
    "รัตนวาปี",
    "ศรีเชียงใหม่",
    "ศรีวิไล",
    "สระใคร",
    "สังคม"
  ],
  "หนองบัวลำภู": [
    "นากลาง",
    "นาวัง",
    "โนนสัง",
    "เมืองหนองบัวลำภู",
    "ศรีบุญเรือง",
    "สุวรรณคูหา"
  ],
  "อ่างทอง": [
    "ไชโย",
    "ป่าโมก",
    "โพธิ์ทอง",
    "เมืองอ่างทอง",
    "วิเศษชัยชาญ",
    "สามโก้",
    "แสวงหา"
  ],
  "อำนาจเจริญ": [
    "ชานุมาน",
    "ปทุมราชวงศา",
    "พนา",
    "เมืองอำนาจเจริญ",
    "ลืออำนาจ",
    "เสนางคนิคม",
    "หัวตะพาน"
  ],
  "อุดรธานี": [
    "กุดจับ",
    "กุมภวาปี",
    "กู่แก้ว",
    "ไชยวาน",
    "ทุ่งฝน",
    "นายูง",
    "น้ำโสม",
    "โนนสะอาด",
    "บ้านดุง",
    "บ้านผือ",
    "ประจักษ์ศิลปาคม",
    "พิบูลย์รักษ์",
    "เพ็ญ",
    "เมืองอุดรธานี",
    "วังสามหมอ",
    "ศรีธาตุ",
    "สร้างคอม",
    "หนองวัวซอ",
    "หนองแสง",
    "หนองหาน"
  ],
  "อุตรดิตถ์": [
    "ตรอน",
    "ทองแสนขัน",
    "ท่าปลา",
    "น้ำปาด",
    "บ้านโคก",
    "พิชัย",
    "ฟากท่า",
    "เมืองอุตรดิตถ์",
    "ลับแล"
  ],
  "อุทัยธานี": [
    "ทัพทัน",
    "บ้านไร่",
    "เมืองอุทัยธานี",
    "ลานสัก",
    "สว่างอารมณ์",
    "หนองขาหย่าง",
    "หนองฉาง",
    "ห้วยคต"
  ],
  "อุบลราชธานี": [
    "กุดข้าวปุ้น",
    "เขมราฐ",
    "เขื่องใน",
    "โขงเจียม",
    "ดอนมดแดง",
    "เดชอุดม",
    "ตระการพืชผล",
    "ตาลสุม",
    "ทุ่งศรีอุดม",
    "นาจะหลวย",
    "นาตาล",
    "นาเยีย",
    "น้ำขุ่น",
    "น้ำยืน",
    "บุณฑริก",
    "พิบูลมังสาหาร",
    "โพธิ์ไทร",
    "ม่วงสามสิบ",
    "เมืองอุบลราชธานี",
    "วารินชำราบ",
    "ศรีเมืองใหม่",
    "สว่างวีระวงศ์",
    "สำโรง",
    "สิรินธร",
    "เหล่าเสือโก้ก"
  ]
}

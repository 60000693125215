import axios from "axios";
import { User } from "firebase/auth";
import { db } from "./firebase";
import { doc, DocumentData, setDoc, getDoc } from "firebase/firestore";

export class CertController {
  apiURL = (local: boolean = false) =>
    local ? `http://localhost:8084` : `https://pi-nest.mek.network`;

  async get(user: User): Promise<null | { link: string }> {
    if (user) {
      const token = await user.getIdToken();
      const { data } = await axios.get(`${this.apiURL()}/cert/t/${token}`);
      return data;
    }
    return null;
  }

  async getInfo(user: User): Promise<DocumentData | null | undefined> {
    if (user) {
      const docRef = doc(db, "clients", "mek-network-", "users", user.uid);
      const snap = await getDoc(docRef);
      return snap.exists() ? snap.data() : null;
    }
    return null;
  }

  async update(user: User, data: { [key: string]: string }) {
    console.log(user, data)
    if (user && data) {
      const docRef = doc(db, "clients", "mek-network-", "users", user.uid);
      setDoc(docRef, data, { merge: true });
    }
  }
}

import { initializeApp } from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";
import "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

export let firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey || "",
  authDomain: process.env.REACT_APP_authDomain || "",
  databaseURL: "https://phra-in-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: process.env.REACT_APP_projectId || "",
  storageBucket: process.env.REACT_APP_storageBucket || "",
  messagingSenderId: process.env.REACT_APP_messagingSenderId || "",
  appId: process.env.REACT_APP_appId || "",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const rt = getDatabase(app)

export { app, db, auth, rt };

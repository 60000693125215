import { useParams, useNavigate } from "react-router-dom";
import { auth } from "../../controllers/firebase";
import { signInWithCustomToken } from "firebase/auth";
import { useEffect } from "react";
import { MainContainer } from "@kgui/core";

export const ViewAs = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      signInWithCustomToken(auth, token)
        .then((user) => {
          navigate("/", { replace: true });
        })
        .catch((err) => console.log(err));
    }
  }, [token, navigate]);

  return <MainContainer> </MainContainer>;
};

import { KuiButton, useCore } from "@kgui/core";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  InputLabel,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { SchoolSelect } from "../school.select";
import { keys } from "./docs";
import { defaultData } from "./docs";
import { CertController } from "../../controllers/cert";

export const StudentInfo = () => {
  const { t, user } = useCore();
  const [control, setControl] = useState<undefined | CertController>();
  const [data, setData] = useState({ ...defaultData });

  const isComplete = keys
    .filter(({ require }) => require)
    .every(({ field }) => Boolean(data[field]));

  const handleChangeSelect =
    (key: string, option: { [key: string]: string } = {}) =>
    ({ target: { value } }: SelectChangeEvent<string>) =>
      setData((d) => ({ ...d, ...option, [key]: value }));

  const handleChangeText =
    (key: string) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      setData((d) => ({ ...d, [key]: value }));

  const handleSave = async () => {
    if (isComplete && user && user.data) {
      const dataInfo = {
        ...data,
        studentName: [data.title, data.fname, data.mname, data.sname]
          .filter((v) => v)
          .join(" "),
        uid: user.data?.uid,
      };
      await control?.update(user.data, dataInfo);
      // window.location.reload();
    }
  };

  useEffect(() => {
    if (user.loading === false) {
      if (user.data) {
        const control = new CertController();
        setControl(control);
        control
          .getInfo(user.data)
          .then((val) => setData((s) => ({ ...s, ...val })));
      }
    }
  }, [user.loading, user.data]);

  return (
    <div>
      {keys.map(({ field, type, label, options }, index) => {
        switch (type) {
          case "select":
            return (
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 16 }}
                key={field}
              >
                <InputLabel>{t(label)}</InputLabel>
                <Select
                  label={t(label)}
                  displayEmpty
                  value={data[field]}
                  onChange={handleChangeSelect(field)}
                  tabIndex={index}
                >
                  {options?.map((val) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          case "text":
            return (
              <TextField
                fullWidth
                key={field}
                label={t(label)}
                value={data[field]}
                onChange={handleChangeText(field)}
                style={{ marginBottom: 16 }}
                tabIndex={index}
              />
            );
          default:
            return null;
        }
      })}
      <SchoolSelect data={data} onChange={handleChangeSelect} />
      <KuiButton
        fullWidth
        variant="contained"
        size="large"
        tx="save"
        onClick={handleSave}
        disabled={!Boolean(isComplete)}
        sx={{ mt: 3 }}
      />
    </div>
  );
};

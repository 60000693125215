import logo from "./logo.svg";
import "./App.css";
import { CoreProvider } from "@kgui/core";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { firebaseConfig } from "./controllers/firebase";
import { ViewHome } from "./View/home";
import { ViewAs } from "./View/as";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/as/:token" element={<ViewAs />} />
        <Route path="/" element={<ViewHome />} />
      </Routes>
    </BrowserRouter>
  );
}

const AppWProvider = () => {
  return (
    <CoreProvider firebaseConfig={firebaseConfig} Link={Link} logo={logo}>
      <App />
    </CoreProvider>
  );
};

export default AppWProvider;

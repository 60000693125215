import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, ContentHeader, MainContainer, useCore } from "@kgui/core";
import { Box, Button, CircularProgress, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CertController } from "../../controllers/cert";
import { faDownload, faLink } from "@fortawesome/pro-duotone-svg-icons";
import { StudentInfo } from "../../components/student.info";

export const ViewHome = () => {
  const { user, t } = useCore();
  const [state, setState] = useState<{ [key: string]: any }>({
    loading: true,
  });
  const [data, setData] = useState<{ [key: string]: string } | null>(null);

  useEffect(() => {
    if (user.loading === false) {
      if (user.data) {
        const control = new CertController();
        control.get(user.data).then((data) => {
          setData(data);
          setState((s) => ({ ...s, loading: false }));
        });
      }
    }
  }, [user.loading, user.data]);

  return (
    <MainContainer signInOnly>
      <Container maxWidth="sm">
        <ContentHeader label="Cert-Gen" breadcrumbs={[{ label: t("Home") }]} />
        {state.loading === true && (
          <Box textAlign={"center"}>
            <CircularProgress />
          </Box>
        )}
        {data?.link && (
          <Box textAlign={"center"}>
            <Button
              variant="contained"
              color="primary"
              LinkComponent={"a"}
              href={data.link}
              target={"_blank"}
              startIcon={<FontAwesomeIcon icon={faDownload} />}
            >
              {t("Download")}
            </Button>
          </Box>
        )}
        {data?.error &&
          (() => {
            switch (data.error) {
              case "infomation-not-complete":
                return <StudentInfo />;
              case "not-assess-yet":
                return (
                  <Box textAlign="center">
                    <Link
                      variant="h6"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSffYX3A8W0mvpj100bbiZi0JczUjY6G5KTYloQF5dQw4sEyGg/viewform?usp=sf_link"
                      target={"_blank"}
                      sx={{ pb: 1, display: "block" }}
                    >
                      <FontAwesomeIcon
                        icon={faLink}
                        style={{ marginRight: "0.5rem" }}
                      />
                      แบบประเมิน
                    </Link>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      textAlign="center"
                      paragraph
                    >
                      กรุณากรอกแบบประเมินโดยใช้ E-mail address ที่ใช้งานกับ
                      Account นี้
                    </Typography>
                  </Box>
                );
              default:
                return (
                  <Typography color="error" textAlign="center">
                    {data?.message}
                  </Typography>
                );
            }
          })()}
      </Container>
    </MainContainer>
  );
};

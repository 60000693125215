import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { provinces } from "./provinces";
import { equalTo, get, orderByChild, query, ref } from "firebase/database";
import { rt } from "../../controllers/firebase";

interface SchoolSelectProps {
  data: { [key: string]: string };
  onChange: (
    key: string,
    option?: { [key: string]: string }
  ) => (event: SelectChangeEvent<string>) => void;
}

interface SchoolTypes {
  address: string;
  area: string;
  district: string;
  filter: string;
  id: string;
  postcode: string;
  province: string;
  tel: string;
  title: string;
}

interface SchoolsTypes {
  loading: boolean;
  docs: SchoolTypes[];
}

export const SchoolSelect = ({ data, onChange }: SchoolSelectProps) => {
  const [schools, setSchools] = useState<SchoolsTypes>({
    loading: false,
    docs: [],
  });

  const schoolValue = schools.docs.map((d) => d.id).includes(data?.school)
    ? data?.school
    : "";

  useEffect(() => {
    if (data.province && data.district) {
      setSchools((s) => ({ ...s, loading: true }));
      const dbRef = ref(rt, `/schools`);
      const q = query(
        dbRef,
        orderByChild("filter"),
        equalTo(`${data.province}-${data.district}`)
      );
      get(q).then((snapshot) => {
        const datas: { [key: number]: SchoolTypes } = snapshot.val();
        const docs: SchoolTypes[] = Object.values(datas).sort(
          (a: SchoolTypes, b: SchoolTypes) => (a?.title).localeCompare(b?.title)
        );
        setSchools((s) => ({
          ...s,
          docs,
          loading: false,
        }));
      });
    }
  }, [data.province, data.district]);

  return (
    <div>
      <Select
        variant="outlined"
        fullWidth
        displayEmpty
        value={data.province || ""}
        onChange={onChange("province", { district: "", school: "" })}
        style={{ marginBottom: 16 }}
      >
        <MenuItem value="">-- เลือกจังหวัด --</MenuItem>
        {Object.keys(provinces).map((key) => (
          <MenuItem value={key} key={key}>
            {key}
          </MenuItem>
        ))}
      </Select>

      {data?.province && (
        <Select
          variant="outlined"
          fullWidth
          displayEmpty
          value={data.district || ""}
          onChange={onChange("district", { school: "" })}
          style={{ marginBottom: 16 }}
        >
          <MenuItem value="">-- เลือกอำเภอ --</MenuItem>
          {(provinces[data?.province] || []).map((key: any) => (
            <MenuItem value={key} key={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
      )}

      {data?.province &&
        data?.district &&
        (schools.loading ? (
          <Box mb={2}>
            <CircularProgress size={32} />
          </Box>
        ) : (
          <Select
            variant="outlined"
            fullWidth
            displayEmpty
            value={schoolValue || ""}
            onChange={onChange("school")}
          >
            <MenuItem value="">-- เลือกโรงเรียน --</MenuItem>
            {schools.docs.map((doc) => (
              <MenuItem value={doc.id} key={doc.id}>
                {doc.title}
              </MenuItem>
            ))}
          </Select>
        ))}
    </div>
  );
};

export const defaultData: {[key: string]: string} = {
    title: "",
    fname: "",
    mname: "",
    sname: "",
    studentId: "",
    province: "",
    district: "",
    school: "",
    grade: "",
    room: "",
  };

export const keys = [
    {
        field: "title",
        label: "Title name",
        type: "select",
        options: [`นาย`, `นางสาว`, `นาง`],
        require: true,
    },
    { field: "fname", label: "First name", type: "text", require: true },
    { field: "mname", label: "Middle name", type: "text" },
    { field: "sname", label: "Surname", type: "text", require: true },
    { field: "studentId", label: "Student ID", type: "text", require: true },
];

export const grades = {
    1: "ป. 1",
    2: "ป. 2",
    3: "ป. 3",
    4: "ป. 4",
    5: "ป. 5",
    6: "ป. 6",
    7: "ม. 1",
    8: "ม. 2",
    9: "ม. 3",
    10: "ม. 4",
    11: "ม. 5",
    12: "ม. 6",
};